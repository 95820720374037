@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'SOURCEHANSANSCN-Light';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SOURCEHANSANSCN-Bold';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SOURCEHANSANSCN-Extralight';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Extralight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SOURCEHANSANSCN-Heavy';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SOURCEHANSANSCN-Medium';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SOURCEHANSANSCN-Normal';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Normal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SOURCEHANSANSCN-Regular';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PangMenZhengDao';
  src: url('https://ziti.dongbaqu.com/PangMenZhengDao.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'YouSheBiaoTiHei';
  src: url('https://ziti.dongbaqu.com/YouSheBiaoTiHei.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'SOURCEHANSANSCN-Light';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}
html {
  &::-webkit-scrollbar {
    width: 4px;
}
&::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    opacity: 0.2;
    background: #1B8CAC;
}
&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: fade(#fff, 30%);
}
}

img {
  max-width: 100%;
  display: block;
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1380px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}


//圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress1 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress2 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  25% {
    opacity: 0.7;
    transform: translateY(-10%);
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  75% {
    opacity: 0.7;
    transform: translateY(10%);
  }

  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "";
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "";
}
.download-nav {
  @include res(height,1.36rem);
  width: 100%;
  ul {
    height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      li {
          @include res(width,106px);
          @include res(height,36px);
          font-family: SourceHanSansCN-Light;
          font-size: 14px;
          letter-spacing: 1px;
          color: #909090;
          @include res(line-height,36px);
          border: solid 1px transparent;
          text-align: center;
          transition: all .3s;
          @include res(margin-right,18px);
          &.active,&:hover {
              border: solid 1px #909090;
              color: #474747;
          }
          &:last-child {
              margin-right: 0;
          }
      }
  }
}