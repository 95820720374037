@import "../../assets/styles/utils";
footer {
    width: 100%;
    @include res(height,3.8rem,(lg:250px,mmd:280px,sm:auto));
    background: url(./images/footer-bg.png)no-repeat;
    background-size: cover;
    position: relative;
    .container {
        @include res(padding-top,0.54rem,(sm:20px));
        @include res(padding-bottom,0,(sm:20px));
        @include res(height,auto,(mmd:200px,sm:auto));
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        .left {
            .phone {
                
                display: flex;
                img {
                    margin-right: 10px;
                    @include res(width,0.36rem);
                }
                p {
                    font-family: YouSheBiaoTiHei;
                    @include res(font-size,0.3rem);
                    color: #1b8cac;
                }
            }
            h3 {
                @include res(margin-top,0.2rem);
                font-family: SourceHanSansCN-Medium;
                @include res(font-size,0.18rem);
                color: #1b8cac;
            }
            dl {
                @include res(margin-top,0.3rem);
                dt {
                    font-family: SourceHanSansCN-Medium;
                    @include res(font-size,0.14rem);
                    color: #ffffff;
                    opacity: .5;
                    @include res(margin-bottom,0.15rem);
                }
                dd {
                    font-family: SourceHanSansCN-Light;
                    @include res(font-size,0.14rem);
                    color: #ffffff;
                    opacity: .5;
                    @include res(margin-bottom,0.1rem);
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .right {
            @include res(margin-top,0,(xs:20px));
            position: relative;
            @include res(width,8.58rem,(lg:65%,sm:100%));
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .footer-nav {
                display: flex;
                dl {
                    width: fit-content;
                    @include res(margin-right,0.63rem,(xs:10px));
                    dt {
                        font-family: SourceHanSansCN-Regular;
                        @include res(font-size,0.14rem);
                        color: #1b8cac;
                    }
                    span {
                        @include res(margin-top,0.14rem);
                        @include res(margin-bottom,0.18rem);
                        display: block;
                        @include res(width,0.13rem);
                        @include res(height,0.02rem);
                        background-color: #1b8cac;
                    }
                    dd {
                        font-family: SourceHanSansCN-Light;
                        @include res(font-size,0.12rem);
                        @include res(margin-bottom,0.1rem);
                        color: #ffffff;
                        opacity: .5;
                        transition: all .3s;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &:hover {
                            opacity: 1;
                        }
                    }
                    &:last-child {
                        @include res(margin-right,0);
                    }
                }
            }
            .footer-ewm {
               @include res(margin,0,(xs:auto));
               @include res(margin-top,0,(xs:20px));
               @include res(margin-bottom,0,(xs:20px));
                h3 {
                    @include res(text-align,left,(xs:center));
                    font-family: SourceHanSansCN-Regular;
                    @include res(font-size,0.14rem);
                    color: #1b8cac;
                    @include res(margin-bottom,0.15rem);

                }
            }
            .title {
                position: absolute;
                @include res(bottom,0,(sm:-10px));
                right: 0;
                display: flex;
                align-items: center;
                h2 {
                    font-family: YouSheBiaoTiHei;
                    @include res(font-size,0.22rem);
                    color: #ffffff;
                    opacity: .5;   
                }
                img {
                    @include res(width,2.5rem,(md:200px));
                    @include res(margin-right,0.28rem);
                }
            }
        }
    }
    .bottom {
        @include res(position,absolute,(sm:relative));
        bottom: 0;
        left: 0;
        width: 100%;
        @include res(height,0.76rem,(xs:100%));
        background-color: #1b8cac;
        @include res(line-height,0.76rem,(xs:normal));
        @include res(padding-top,0,(xs:10px));
        @include res(padding-bottom,0,(xs:10px));
        p {
            font-family: SourceHanSansCN-Normal;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            @include res(text-align,left,(xs:center));
            a {
                display: block;
                @include res(margin-left,10px);
            }
        }
    }
    .backtop {
        @include res(display,block,(xs:none));
        cursor: pointer;
        @include res(width,50px);
        @include res(height,50px);
        z-index: 9999;
        position: fixed;
        @include res(bottom,0.3rem);
        @include res(right,0.3rem);
    }
}