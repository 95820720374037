@import "../../assets/styles/utils";
header {
    @include res(height,80px);
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;
    transition: all .3s;
    .head {
        @include res(width,16rem,(sm:18rem,xs:90%));
        margin: auto;
        @include res(height,38px,(xs:70px));
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        .logo1 {
            @include res(width,180px,(sm:140px));
        }
        .logo2 {
            display: none;
            @include res(width,180px,(sm:140px));
        }
        .nav {
            @include res(display,flex,(xs:none));
            align-items: center;
            li {
                position: relative;
                font-family: SourceHanSansCN-Light;
                @include res(font-size,14px);
                @include res(margin-right,0.57rem);
                color: #fff;
                height: 38px;
                line-height: 38px;
                &:last-child {
                    margin-right: 0;
                    a {
                        padding-bottom: 5px;
                    }
                }
                &.active {
                    a {
                        border-color: #fff;
                    }
                }
                a {
                    padding-bottom: 5px;
                    border-bottom: 1px solid transparent;
                    transition: all .3s;
                    &:hover {
                        border-color: #fff;
                    }
                }
                .li-drop {
                    padding: 20px 0;
                    display: none;
                    width: 90px;
                    position: absolute;
                    @include res(top,40px);
                    background-color: #ffffff;
                    left: -14px;
                    border-radius: 4px;
                    a {
                        color: #919191;
                        height: auto;
                        display: block;
                        text-align: center;
                        line-height: normal;
                        @include res(margin-bottom,0.1rem);
                        transition: all .3s;
                        border: none!important;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &:hover {
                            color: #474747;
                        }
                    }
                }
            }
        }
        &.head1 {
            .logo1 {
                display: none;
            }
            .logo2 {
                display: block;
            }
            .nav {
                li {
                    color: #474747;
                    &.active,&:hover {
                        a {
                            border-color: #1B8CAC;
                        }
                    }
                    .li-drop {
                        @include res(top,40px);
                    }
                }
            }
        }
        .phone {
            @include res(display,none,(xs:block));
        }
        
    }
    &.head_scroll {
        background: #fff;
        @include res(height,80px);
        display: flex;
        align-items: center;
        justify-content: center;
        .head {
            @include res(padding-bottom,0);
            position: relative;
            .logo1 {
                display: none;
            }
            .logo2 {
                display: block;
            }
            box-sizing: content-box;
            // @include res(bottom,30px);
            ul {
                li {
                    a {
                        &:hover {
                            border-color: #1B8CAC;
                        }
                    }
                    &.active {
                        a {
                            border-color: #1B8CAC;
                        }
                    }
                    height: 68px;
                    line-height: 68px;
                }
            }
        }
    }
    .phone-header {
        .mob-nav {
            width: 100%;
            height: 100vh;
            background-color: #f7f7f7;
            position: fixed;
            top: 70px;
            right: 100%;
            z-index: 99;
            color: #1B8CAC;
            box-sizing: border-box;
            overflow-y: auto;
            transition: .4s cubic-bezier(.77,0,.175,1);
            padding-top: 30px;
            .nLi {
                &.active {
                    img {
                        transform: rotate(90deg);
                    }
                }
                h3 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid rgba(0,73,130,.3);
                    margin: 0 20px;
                    // padding: 0 20px;
                    .arrow {
                        width: 45px;
                        display: flex;
                        justify-content: end;
                    }
                    a {
                        flex-grow: 1;
                        line-height: 45px;
                        font-size: 16px;
                    }
                }
            }
            &.active {
                right: 0;
            }
            .sub {
                display: none;
                border-bottom: 1px solid rgba(0,73,130,.3);
                li {
                    a {
                        display: block;
                        padding-left: 20px;
                        font-size: 16px;
                        line-height: 40px;
                        color: #1B8CAC;
                    }
                }
            }
        }
    }
}