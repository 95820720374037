@import "../../assets/styles/utils";
.content-page {
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            @include res(margin-right,10px);
            @include res(width,0.5rem);
            @include res(height,0.5rem);
            border-radius: 0.04rem;
            background: rgba(255,255,255,.4);
            transition: all .3s;
            a {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: SourceHanSansCN-Regular;
                @include res(font-size,0.18rem);
                color: #474747;
                .img2 {
                    display: none;
                }
            }
            &:last-child {
                margin-right: 0;
            }
            &:hover,&.active {
                background: rgba(255,255,255,1);
                a {
                    color: #1b8cac;
                    .img1 {
                        display: none;
                    }
                    .img2 {
                        display: block;
                    }
                }
                &.page-prev {
                    background: url(./images/left-bg.png)no-repeat;
                    background-size: cover;
                }
                &.page-next {
                    background: url(./images/right-bg.png)no-repeat;
                    background-size: cover;
                }
            }
            &.page-prev {
                @include res(width,1rem);
                @include res(height,0.5rem);
                background: url(./images/left-bg.png)no-repeat;
                background-size: cover;
            }
            &.page-next {
                @include res(width,1rem);
                @include res(height,0.5rem);
                background: url(./images/right-bg.png)no-repeat;
                background-size: cover;
            }
        }
    }
}